import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/thierry/travail/Obi2b/site-obi2b/src/components/other/other-layout.tsx";
import SEO from '../../components/global/seo';
import FormationImage from '../../components/formation/angular/image';
import FormationLayout from '../../components/formation/formation-layout';
import FormationHeader from '../../components/formation/angular/header.mdx';
import FormationMain from '../../components/formation/angular/main.mdx';
import FormationProgram from '../../components/formation/angular/program.mdx';
import FormationTrainer from '../../components/formation/angular/trainer.mdx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Formation Angular" mdxType="SEO" />
    <FormationLayout mdxType="FormationLayout">
    <div className="row">
        <section className="header">
            <h1>Formation Angular</h1>
            <FormationHeader mdxType="FormationHeader" />
        </section>
        <section className="image">
            <FormationImage mdxType="FormationImage" />
        </section>
    </div>
    <div className="row">
        <section className="main">
            <FormationMain mdxType="FormationMain" />
        </section>
        <section className="trainer">
            <FormationTrainer mdxType="FormationTrainer" />
        </section>
    </div>
    <div className="row">
        <section className="program">
            <FormationProgram mdxType="FormationProgram" />
        </section>
    </div>
    </FormationLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      